import React, { useEffect, useRef, useState, memo } from 'react';
import PropTypes from 'prop-types';
import Banner from '../../components/Banner/index';
import SubNavigation from '../SubNavigation';
import ProductBag from '../ProductBag';
import BagIcon from '../BagIcon';
import UserIcon from '../../images/icons/user-profile.png';
import SearchBox from '../Banner/SearchBox';
import LoginModal from '../LoginModal';
import { useHistory } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import * as action from '../../redux/auth/actions';
import { prefix } from '../../utils/customHooks/usePrefixSegment';
import { PREFIX_URL_DEFAULT, PREFIX_URL_KIDS, SUNDORA_KIDS_ACTIVE } from '../../config';
import SundoraKidsIcon from '../../images/kids_icon_logo_comp.png';
import SundoraBeautyIcon from '../../images/sundora-beauty-icon.png';

function Navigation({ data = [] }: { data: any }) {
  const ref = useRef<any>(null);
  const dispatch = useDispatch();
  const [isSticky, setSticky] = useState<boolean>(false);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<{
    index: number | null;
    active: boolean;
  }>({
    index: null,
    active: false,
  });

  const userReducer = useSelector((state: RootStateOrAny) => state?.userReducer);
  const pathname = window.location.pathname;

  useEffect(() => {
    window.addEventListener('scroll', _handleScroll);
    return () => window.removeEventListener('scroll', _handleScroll);
  }, []);

  useEffect(() => {
    if (prefix === PREFIX_URL_KIDS) {
      const items: any = document.querySelectorAll('.title-item');
      const colors = ['#DF1F26', '#F4A51D', '#3BA949', '#E3097E'];
      const len_colors = colors.length;
      let color_index = 0;
      for (let i = 0; i < items.length; i++) {
        items[i].style.cssText = `color: ${colors[color_index]} !important`;
        if (color_index + 1 < len_colors) {
          color_index++;
        } else {
          color_index = 0;
        }
      }
    }
  }, [data, pathname]);

  const _handleScroll = () => {
    if (ref && ref.current) {
      const offset = window.scrollY;
      const height = ref.current.clientHeight;
      if (offset > height) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    }
  };

  const handelActiveSubNav = (index: any) => {
    setIsOpen({
      index: index,
      active: true,
    });
  };

  const handelCloseSubNav = () => {
    if (isOpen.active) {
      setIsOpen({
        index: null,
        active: false,
      });
    }
  };

  const handelCloseOpenedNav = () => {
    setIsOpen((prev) => ({
      index: prev.index,
      active: false,
    }));
  };

  const handleClickUserIc = () => {
    const AUTH_TOKEN = localStorage.getItem('sundoraToken');
    AUTH_TOKEN
      ? history.push('/account/my-account')
      : dispatch(action.toggleModalLoginActions(true));
  };

  const reRouteToRoot = (param = '') => {
    let redirect_url = window.location.origin;
    if (param != '') {
      redirect_url = window.location.origin + '/' + param + '/';
    }
    window.location.href = redirect_url;
  };

  return data ? (
    <header>
      <section className={`div-headerHome ${isSticky ? 'sticky' : ''}`}>
        <div className="menu-header">
          <Banner />
          {pathname === '/store/register' ? (
            <></>
          ) : (
            <div
              className={`navigation-block`}
              ref={ref}
              onMouseLeave={() => handelCloseOpenedNav()}
            >
              <ul className="navigation-content">
                {data.map((item: any, index: any) => {
                  if (item?.visible_only_to_logged_in_users) {
                    if (!userReducer?.user) {
                      return;
                    }
                  }

                  return (
                    <li
                      key={index}
                      className={`navigation-content__item ${
                        isOpen.index === index && isOpen.active == true ? 'active' : ''
                      }`}
                      style={{ color: item?.menu_color }}
                    >
                      {(item?.redirect_url && item?.redirect_url?.relative_url) ||
                      item?.external_url ? (
                        <a
                          // href={item.redirect_url.relative_url}
                          onClick={() => {
                            if (item?.external_url) {
                              window.location.href = item?.external_url;
                            }
                            history.push(item.redirect_url.relative_url);
                          }}
                          className="title-item"
                          onMouseOver={(e) => {
                            e.stopPropagation();
                            item?.children?.length > 0 || item?.products?.headline
                              ? handelActiveSubNav(index)
                              : '';
                          }}
                        >
                          {item?.menu_link_icon && (
                            <img src={item?.menu_link_icon} alt={item.title} />
                          )}
                          {item.title}
                        </a>
                      ) : item.children.length > 0 || item.products.headline ? (
                        <p
                          className="title-item"
                          onMouseOver={(e) => {
                            e.stopPropagation();
                            item.children.length > 0 || item.products.headline
                              ? handelActiveSubNav(index)
                              : '';
                          }}
                        >
                          {item?.menu_link_icon && (
                            <img src={item?.menu_link_icon} alt={item.title} />
                          )}
                          {item.title}
                        </p>
                      ) : (
                        <a
                          onClick={() => history.push(item.relative_url)}
                          // href={item.relative_url}
                          className="title-item"
                          onMouseOver={(e) => {
                            e.stopPropagation();
                            item.children.length > 0 || item.products.headline
                              ? handelActiveSubNav(index)
                              : '';
                          }}
                        >
                          {item?.menu_link_icon && (
                            <img src={item?.menu_link_icon} alt={item.title} />
                          )}
                          {item.title}
                        </a>
                      )}
                    </li>
                  );
                })}
                <li>
                  {prefix == PREFIX_URL_DEFAULT && SUNDORA_KIDS_ACTIVE ? (
                    <a
                      className="kids-menu-item"
                      onClick={() => reRouteToRoot(PREFIX_URL_KIDS)}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={SundoraKidsIcon}
                        style={{ height: '22px', marginRight: '5px' }}
                      />
                      SUNDORA KIDS
                    </a>
                  ) : prefix != PREFIX_URL_DEFAULT ? (
                    <a
                      className="beauty-menu-item"
                      onClick={() => reRouteToRoot('')}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={SundoraBeautyIcon}
                        style={{ height: '22px', marginRight: '5px' }}
                      />
                      SUNDORA BEAUTY
                    </a>
                  ) : (
                    <></>
                  )}
                </li>

                <li>
                  <div
                    className={`banner-with-detail animated faster ${
                      isSticky ? 'fadeIn' : 'fadeOut'
                    }`}
                  >
                    <SearchBox />
                    <button className="btn-login" onClick={handleClickUserIc}>
                      <img src={UserIcon} alt="user-icon" />
                    </button>
                    <BagIcon />
                  </div>
                </li>
              </ul>
              {data.map((item: any, index: any) => {
                return item.children.length > 0 ? (
                  <SubNavigation
                    isOpen={isOpen.index === index && isOpen.active == true ? true : false}
                    title={item.title}
                    key={index}
                    dataChild={item.children}
                    promote={item?.promote}
                    closeSubNav={handelCloseSubNav}
                  />
                ) : (
                  ''
                );
              })}
              <div
                className={`${isOpen.active == true ? 'mask' : 'mask-hidden'}`}
                onMouseOver={handelCloseSubNav}
              ></div>
            </div>
          )}
        </div>
      </section>
      <ProductBag />
      {localStorage.getItem('sundoraToken') ? '' : <LoginModal />}
    </header>
  ) : (
    <></>
  );
}

Navigation.propTypes = {
  data: PropTypes.array.isRequired,
};

export default memo(Navigation);
