import { isMobile } from 'react-device-detect';
import {
  DEFAULT_FONT_COLOR,
  DEFAULT_MAIN_COLOR,
  DEFAULT_SECONDARY_COLOR,
  LOCALSTORAGE_KEY_USER_LOCATION,
  PREFIX_URL_HOME,
  PREFIX_URL_KIDS,
  PREFIX_URL_SPORTS,
} from '../../config';
import { prefix } from '../customHooks/usePrefixSegment';
import store from '../../redux/store';
import slugify from 'slugify';

export const customBrandFilter = (data: any) => {
  if (!data) {
    return null;
  }

  const brands: any = [];
  data?.forEach((e: any) => {
    e?.brands?.forEach((brand: any) => {
      if (!brands?.find((b: any) => b?.id == brand?.id)) {
        brands?.push(brand);
      }
    });
  });

  return brands;
};

export function getRenderedSize(
  contains: any,
  cWidth: any,
  cHeight: any,
  width: any,
  height: any,
  pos: any,
) {
  const oRatio = width / height,
    cRatio = cWidth / cHeight;
  return function (this: any) {
    if (contains ? oRatio > cRatio : oRatio < cRatio) {
      this.width = cWidth;
      this.height = cWidth / oRatio;
    } else {
      this.width = cHeight * oRatio;
      this.height = cHeight;
    }
    this.left = (cWidth - this.width) * (pos / 100);
    this.right = cWidth - this.width - this.left;
    this.top = (cHeight - this.height) * (pos / 100);
    return this;
  }.call({});
}

export function getImgSizeInfo(img: any) {
  const pos = window.getComputedStyle(img).getPropertyValue('object-position').split(' ');
  return getRenderedSize(
    true,
    img.width,
    img.height,
    img.naturalWidth,
    img.naturalHeight,
    parseInt(pos[0]),
  );
}

export const filterSlides = (slides: any, userReducer: any) => {
  const renderSlides: any = slides?.filter((slide: any) => {
    if ((isMobile && !slide?.mobile) || (!isMobile && slide?.mobile)) {
      return false;
    }
    if (slide?.local_user_only && userReducer?.userLocation != 'BD') {
      return false;
    }
    if (slide?.show_only_for_user == 'logged_in_user_only') {
      if (!userReducer?.user) {
        return false;
      }
    }
    if (slide?.show_only_for_user == 'guest_user_only') {
      if (userReducer?.user) {
        return false;
      }
    }
    return true;
  });
  return renderSlides;
};

export const getFeaturedProductsIdsQueryString = (body: any) => {
  let featuredProductsIdsQueryString = '';
  const featuredProducts: any = body?.find((x: any) => x?.type == 'featured_products')?.value
    ?.products;
  featuredProducts?.forEach((featuredProduct: any) => {
    featuredProductsIdsQueryString += featuredProduct?.value?.id + ',';
  });
  return featuredProductsIdsQueryString
    ? '&featured_products=' + featuredProductsIdsQueryString
    : '';
};

export const filterProducts = (products: any) => {
  const userLocation: any = localStorage.getItem(LOCALSTORAGE_KEY_USER_LOCATION) || 'None';
  if (userLocation != 'BD') {
    return products;
  }
  const newProducts: any = [];
  products?.forEach((product: any) => {
    if (product?.value?.max_inventory_qt > 0 || product?.max_inventory_qt) {
      newProducts?.push(product);
    }
  });
  return newProducts;
};

export const openLoginPopup = () => {
  const btnLogins: any = document.querySelectorAll(`.btn-login`);
  btnLogins?.forEach((btnLogin: any) => {
    btnLogin?.click();
  });
  return;
};

export const directDetailProduct = (history: any, handle: any, brandName: any) => {
  history.push((prefix && '/' + prefix) + '/brand/' + brandName + '/' + handle, {
    from: history.location.pathname + history.location.search,
  });
};

export const getLinkDetailProduct = (handle: any, brandName: any) => {
  return (prefix && '/' + prefix) + '/brand/' + brandName + '/' + handle;
};

export const isNumeric = (num: any) =>
  (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) &&
  !isNaN(num as number);

export const getDiscountLineItem = (lineItems: any) => {
  if (lineItems?.applied_discount?.value_type == 'fixed_amount') {
    return `-৳${lineItems?.applied_discount?.amount}`;
  } else {
    return `-${Math.round(lineItems?.applied_discount?.value)}%`;
  }
};

export const getCartTotal = (lineItems: any) => {
  let cartTotal: any = 0;
  lineItems?.forEach((item: any) => {
    cartTotal += parseFloat(item?.price) * parseInt(item?.quantity);
  });
  return cartTotal;
};

export const getProductDiscounts = (lineItems: any) => {
  let productDiscounts: any = 0;
  lineItems?.forEach((item: any) => {
    productDiscounts += parseFloat(item?.applied_discount?.amount || 0);
  });
  return productDiscounts;
};

export const getColors = () => {
  let mainColor: any = DEFAULT_MAIN_COLOR;
  let secondaryColor: any = DEFAULT_SECONDARY_COLOR;
  let fontColor: any = DEFAULT_FONT_COLOR;
  const globalConfigReducer: any = store.getState()?.globalConfigReducer;
  const basicSettings: any = globalConfigReducer?.basicSettings;
  const userReducer: any = store.getState()?.userReducer;
  const user: any = userReducer?.user;
  mainColor = basicSettings?.main_color_general;
  secondaryColor = basicSettings?.separator_color_general;
  fontColor = basicSettings?.font_color_general;

  if (user) {
    mainColor = basicSettings?.main_color_registered_user || basicSettings?.main_color_general;
    secondaryColor =
      basicSettings?.separator_color_registered_user || basicSettings?.separator_color_general;
    fontColor = basicSettings?.font_color_registered_user || basicSettings?.font_color_general;
  }

  if (prefix == PREFIX_URL_KIDS) {
    mainColor = '#29ABE2';
    secondaryColor = '#FFE52E';
    fontColor = '#000000';
  }

  if (prefix == PREFIX_URL_HOME) {
    mainColor = '#7D1323';
  }

  if (prefix == PREFIX_URL_SPORTS) {
    mainColor = '#0d66A1';
  }

  return {
    mainColor: mainColor,
    secondaryColor: secondaryColor,
    fontColor: fontColor,
  };
};

export const createLinkToBrandPage = (brandName: any) => {
  const charCode: any = brandName?.charCodeAt(0);
  return `/brands/all-brands-${
    charCode >= 65 && charCode <= 72 ? 'a-h' : charCode >= 73 && charCode <= 78 ? 'i-n' : 'o-z'
  }/${slugify(brandName?.toLowerCase())}`;
};

export const calculatePriceDiscount = (value: any, discountValue: any) => {
  return ((parseFloat(value) * (100 - parseFloat(discountValue || 0))) / 100)?.toFixed();
};
