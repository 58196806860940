import React, { useEffect, useState } from 'react';

const CookieModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [acceptedCookiePolicy, setAcceptedCookiePolicy] = useState<any>(true);
  const now = Date.now();
  const cookiePolicyAccepted = {
    accepted: acceptedCookiePolicy,
    timestamp: now,
  };

  useEffect(() => {
    const cookiePolicyAcceptedInLocalSorage = localStorage.getItem('cookiePolicyAccepted');
    if (cookiePolicyAcceptedInLocalSorage) {
      setAcceptedCookiePolicy(true);
    } else {
      setAcceptedCookiePolicy(false);
    }
  }, []);

  useEffect(() => {
    if (!acceptedCookiePolicy) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [acceptedCookiePolicy]);

  const acceptCookiePolicy = () => {
    setAcceptedCookiePolicy(true);
    cookiePolicyAccepted.accepted = true;
    localStorage.setItem('cookiePolicyAccepted', JSON.stringify(cookiePolicyAccepted));
  };

  return (
    <section className={isOpen ? 'open cookie-modal' : 'closed'}>
      <div className="cookie-modal-content">
        <div>
          <h3 id="cookie-header">Cookies</h3>
          <p id="cookie-text">
            We use cookies to optimise site functionality and enhance your experience. To Learn
            more, visit our &nbsp;
            <a
              href="https://sundora.com.bd/about-us/cookie-policy/"
              rel="noreferrer"
              target="_blank"
              id="cookie-policy-link"
            >
              Cookie Policy
            </a>
            &nbsp; page.
          </p>
        </div>
        <div>
          <button className="cookie-policy-button" onClick={acceptCookiePolicy}>
            Accept Cookie Policy
          </button>
        </div>
      </div>
    </section>
  );
};

export default CookieModal;
