import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import Navigation from '../Navigation';
import './styles.scss';
import NavigationMobileV2 from '../NavigationMobileV2';
import NavigationMobileBottom from '../NavigationMobileBottom';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import Whatsapp from '../Whatsapp';
//import BlockFBMessenger from '../BlockFBMessenger';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import chatIcon from '../../assets/images/customer-service-agent.svg';
import Alert from '@mui/material/Alert';
function Header(): JSX.Element {
  const globalConfigReducer = useSelector((state: any) => state?.globalConfigReducer);
  const dataNav: any = globalConfigReducer?.nav?.navigation;
  const [alertOpen, setAlertOpen] = useState(false);
  const callCustomerService = () => {
    const d = new Date();
    const day = d.getDay();
    const hour = d.getHours();

    if (day == 5 || day == 6) {
      setAlertOpen(true);
    } else if (hour <= 10 || hour >= 18) {
      setAlertOpen(true);
    } else {
      window.open('tel:+8801966444455');
    }
  };
  const CallAlert = () => {
    return (
      <>
        <Alert
          sx={{ position: 'absolute', marginTop: '-150px', zIndex: '10000' }}
          severity="warning"
          onClose={() => {
            setAlertOpen(false);
          }}
        >
          Customer Service Representatives are available between 10am and 6pm Sunday to
          Thusday, except holidays.
        </Alert>
      </>
    );
  };

  const actions = [
    { icon: <Whatsapp />, name: 'Whatsapp' },
    //{ icon: <BlockFBMessenger />, name: 'Messenger' },
    { icon: <CallRoundedIcon />, name: 'Call', action: callCustomerService },
  ];
  const pathname = window.location.pathname;
  return pathname !== '/store/register' ? (
    <>
      {dataNav && isMobile && (
        <>
          <NavigationMobileV2 data={dataNav} /> <NavigationMobileBottom data={dataNav} />
        </>
      )}
      {dataNav && !isMobile && (
        <>
          {alertOpen ? <CallAlert /> : <></>}
          <Navigation data={dataNav} />
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'fixed', bottom: 10, right: 23 }}
            icon={<SpeedDialIcon icon={<img src={chatIcon} />} />}
            className="speed-dial"
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.action}
              />
            ))}
          </SpeedDial>
        </>
      )}
    </>
  ) : (
    <></>
  );
}

export default Header;
