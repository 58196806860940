import React from 'react';
import { Link } from 'react-router-dom';

const PromotePageForMenu = ({ data }: { data: any }) => {
  return (
    <div className="component-PromotePageForMenu">
      <Link
        target={data?.open_in_new_tab && '_blank'}
        to={data?.page}
        className="component-PromotePageForMenu-image"
      >
        <img
          src={data?.image.url}
          alt="promote page"
          style={{
            maxWidth: data?.image.custom_width ? data?.image.custom_width : '',
            maxHeight: data?.image.custom_height ? data?.image.custom_height : '',
          }}
        />
        <h3 className="component-PromotePageForMenu-title">{data?.title}</h3>
      </Link>
    </div>
  );
};

export default PromotePageForMenu;
