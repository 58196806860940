import React, { useState } from 'react';
import './style.scss';
import { prefix } from '../../utils/customHooks/usePrefixSegment';
import homeIcon from '../../assets/images/home-outline.svg';
import gridIcon from '../../assets/images/menu.svg';
import profileIcon from '../../assets/images/profile-circle.svg';
import chatIcon from '../../assets/images/customer-service-agent.svg';
import { Link, useHistory } from 'react-router-dom';
import { getColorImage } from '../../utils/helpers/colorCodeToFilter';
import { getColors } from '../../utils/helpers/functions';
import { useDispatch } from 'react-redux';
import { toggleModalLoginActions } from '../../redux/auth/actions';
import LoginModal from '../LoginModal';
import NavigationMobileCategory from '../NavigationMobileCategory';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
//import BlockFBMessenger from '../BlockFBMessenger';
import { DEFAULT_MAIN_COLOR } from '../../config';
import Whatsapp from '../Whatsapp';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import Alert from '@mui/material/Alert';

const NavigationMobileBottom = ({ data }: any) => {
  const history: any = useHistory();
  const dispatch: any = useDispatch();
  const { mainColor } = getColors();
  const [openCategory, setOpenCategory] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const CallAlert = () => {
    return (
      <>
        <Alert
          sx={{ position: 'absolute', marginTop: '-150px', zIndex: '10000' }}
          severity="warning"
          onClose={() => {
            setAlertOpen(false);
          }}
        >
          Customer Service Representatives are available between 10am and 6pm Sunday to
          Thusday, except holidays.
        </Alert>
      </>
    );
  };

  const css: any = `
    .component-NavigationMobileBottom .menu-img{
      filter: ${getColorImage(mainColor || DEFAULT_MAIN_COLOR)} !important;
    }
  `;

  const handleClickUserIc = () => {
    const AUTH_TOKEN = localStorage.getItem('sundoraToken');
    if (AUTH_TOKEN) {
      history.push('/account/my-account');
    } else {
      dispatch(toggleModalLoginActions(true));
    }
  };

  const callCustomerService = () => {
    const d = new Date();
    const day = d.getDay();
    const hour = d.getHours();

    if (day == 5 || day == 6) {
      setAlertOpen(true);
    } else if (hour <= 10 || hour >= 18) {
      setAlertOpen(true);
    } else {
      window.open('tel:+8801966444455');
    }
  };

  const actions = [
    { icon: <Whatsapp />, name: 'Whatsapp' },
    //{ icon: <BlockFBMessenger />, name: 'Messenger' },
    { icon: <CallRoundedIcon />, name: 'Call', action: callCustomerService },
  ];

  return (
    <div className="component-NavigationMobileBottom">
      {alertOpen ? <CallAlert /> : <></>}
      <LoginModal />
      <NavigationMobileCategory data={data} open={openCategory} setOpen={setOpenCategory} />
      <style>{css}</style>
      <ul>
        <li>
          <Link to={`/${prefix}`}>
            <div>
              <img src={homeIcon} alt="home" className="menu-img" />
            </div>
            <div>HOME</div>
          </Link>
        </li>
        <li>
          <a onClick={() => setOpenCategory(true)}>
            <div>
              <img src={gridIcon} alt="categories" className="menu-img" />
            </div>
            <div>MENU</div>
          </a>
        </li>
        <li>
          <a onClick={handleClickUserIc}>
            <div>
              <img src={profileIcon} alt="account" className="menu-img" />
            </div>
            <div>ACCOUNT</div>
          </a>
        </li>
        <li>
          <a>
            <div>
              <img src={chatIcon} alt="chat" className="menu-img" />
            </div>
            <div>CUSTOMER CARE</div>
          </a>
        </li>
      </ul>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 10, right: 23 }}
        icon={<SpeedDialIcon />}
        className="speed-dial"
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.action}
          />
        ))}
      </SpeedDial>
    </div>
  );
};

export default NavigationMobileBottom;
